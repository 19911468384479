import { Link, graphql, StaticQuery } from "gatsby"
import React from "react"

const Navbar = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={data => (
        <header>
          <nav className={"nav"}>
            <Link to={"/"}>
              {data.site.siteMetadata.title}
            </Link>
            <div className={"nav-menu"}>
              {data.site.siteMetadata.menuLinks.map(page => (
                <Link to={page.link}>
                  {page.name}
                </Link>
              ))}
            </div>
          </nav>
        </header>
      )}
    />
  )
}

export default Navbar