import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import ScrollToTop from "react-scroll-to-top"
import { FaArrowUp } from "react-icons/fa"
import { SEO } from "../components/seo"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <SEO />
      <div className={"container"}>
        <Header />
        {children}
        <Footer />
        <ScrollToTop smooth component={<FaArrowUp style={{
          marginTop: -4
        }}/>} 
        style={{
          borderRadius: 100
        }}/>
      </div>
    </React.Fragment>
  )
}

export default Layout