import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { SocialIcon } from 'react-social-icons';

const Footer = () => {

  const currentYear = new Date().getFullYear()

  return (
    <StaticQuery
      query={graphql`
        query {
          strapiFooter {
            Copy {
              data {
                Copy
              }
            }  
          }
          strapiSocial {
            Network {
              URL
            }
          }
        }
      `}
      render={data => (
        <footer>
          <div className={"footer-content"}>
            <div>
              <ReactMarkdown>
                {data.strapiFooter.Copy.data.Copy}
              </ReactMarkdown>
            </div>
            <div className={"socials"}>
              {data.strapiSocial.Network.map(network => (
                <SocialIcon url={network.URL} bgColor={"#efefef"} target={"_blank"}/>
              ))}
            </div>
          </div>
          <div className={"copyright"}>
            <p>© {currentYear} ill-starred night. All rights reserved.</p>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer